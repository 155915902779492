Modernizr.addTest('webkit-appearance', function() {
    return Modernizr.testProp('webkitAppearance');
});
// shim layer with setTimeout fallback
window.requestAnimFrame = (
  function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          window.oRequestAnimationFrame      ||
          window.msRequestAnimationFrame     ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };
  }
)();

function initHeroScroller($){
  var lastScrollY     = 0,
      ticking         = false;

  /**
   * Callback for our scroll event - just
   * keeps a track on the last scroll value
   */

  var $win = $(window);
  function onScroll() {
      $win.data('scrollY', window.scrollY);
      requestTick();
  }

  /**
   * Calls rAF if it's not already
   * been done already
  */
  function requestTick() {
      if(!ticking) {
          requestAnimFrame(update);
          ticking = true;
      }
  }

  /**
   * Animation callback
   */
  function mover(speed, offsetX, element){
    function move(){
      var s = -($win.data('scrollY') * speed)+'px';
      if(element){
        element.css({
          "transform": "translate("+offsetX+","+s+")"
        });
      }
    }
    return {'move':move};
  }


  var base = mover(0.07, '0',$('.hero-img'));
  var top = mover(-0.01, '-50%',$('.hero-img > span'));

  function update() {
      base.move();
      top.move();
      // allow further rAFs to be called
      ticking = false;
  }

  // only listen for scroll events for non-touch devices
  if(!Modernizr.touchevents){
    window.addEventListener('scroll.unikea', onScroll, false);
  }
}

function resizeHandler(){
    $.fn.setAllToMaxHeight = function(){
      return this.height( Math.max.apply(this, $.map( this , function(e){ return $(e).height(); }) ) );
    };
    var $texts = $('.popular-products--text');
    return function() {
      $texts.css('height','auto').setAllToMaxHeight();
    };
  }

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.menu-tuotteet > a').on('click.unikea', function(event){
          if($(window).width() > 767 && Modernizr.touchevents){
            event.preventDefault();
          }
        });

        if(Modernizr.sessionstorage && !sessionStorage.getItem('consent')){
          $('body').addClass('no-cookie-consent');
        }

        $('.accept-cookies').on('click.unikea', function(event){
          if(Modernizr.sessionstorage){
            sessionStorage.setItem('consent', 'accepted');
            $('body').removeClass('no-cookie-consent');
          }
        });

        $('.container-global-navigation').on('touchmove.unikea',function(event){
          event.preventDefault();
        });

        //mount riot
        riot.mount('*');
        // load more posts

        function makeClickableBlocks(){
          $('[data-clickblock]').off('click.unikea').on('click.unikea', function(event){
            event.preventDefault();
            document.location.href = $(this).data('clickblock');
          });
        }
        makeClickableBlocks();

        $('.posts-navigation .nav-previous a').on('click.unikea', function(event){
          event.preventDefault();
          var $t = $(this),
              _url = $t.attr('href'),
              $_dummy = $('<div/>'),
              $container = $('#posts-container');

          if(true === $t.attr('loading')){
            return;
          }

          $t.attr('loading', true);

          $_dummy.load(_url + ' main.content', function(){

            $container.append($_dummy.find('article'));

            if($_dummy.find('.posts-navigation .nav-previous a').length){
              $t.attr('href', $_dummy.find('.posts-navigation .nav-previous a').attr('href'));
            }
            else{
              $t.hide();
            }

            $t.attr('loading', false);
            makeClickableBlocks();
          });

        });

        $('#toggle-mobile-menu').on('click.unikea', function(event){
          event.preventDefault();
          $('html').toggleClass('site-navigation-open');
        });

        $('#toggle-blog-menu').on('click.unikea', function(event){
          event.preventDefault();
          $('html').toggleClass('blog-navigation-open');
        });

        initHeroScroller(window.jQuery);

        var colHeights = resizeHandler();
        colHeights();
        $(window)
          .on('resize.unikea', colHeights);
      },
      finalize: function() {

        function initPhotoSwipeFromDOM(el){
          var $pswp = $('.pswp')[0];
          var image = [];
          var items;
          $(el).each( function() {
              var $pic     = $(this),
                  getItems = function() {
                      items = [];
                      $pic.find('.photoswipe-href').each(function(i,el) {

                          var $a = $(this),
                              $href   = $a.attr('href'),
                              $size   = $a.data('size').split('x'),
                              $width  = $size[0],
                              $height = $size[1],
                              $title  = $a.next('figcaption').text();

                          $a.closest('figure').data('index', i);

                          var item = {
                              src : $href,
                              w   : $width,
                              h   : $height,
                              title: $title
                          };

                          items.push(item);
                      });
                      return items;
                  };

              var items = getItems();

              $pic.off('click.pswp'); // remove old listener
              $pic.on('click.pswp', 'figure', function(event) {
                  event.preventDefault();
                  var _index = $(this).data('index');
                  var options = {
                      index: _index,
                      bgOpacity: 0.85,
                      showHideOpacity: true
                  };

                  var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                  lightBox.init();
              });
          });
        }

        $(window).on('instagram.unikea',function(){
          initPhotoSwipeFromDOM('.photo-gallery');
        });

        if($('.page-template-template-article').length){
          initPhotoSwipeFromDOM('.article-content');
        }

        initPhotoSwipeFromDOM('.swipe-gallery');
        initPhotoSwipeFromDOM('.swipe-gallery--precisethumbs');

        $('#contact').css('min-height', $('.col__footer--contact-content').outerHeight()+50);

      }
    },
    // Home page
    'home': {
      init: function() {
        var $wrap = $('.frontpage-wrap'),
            $main = $wrap.find('main'),
            $hero = $wrap.find('.hero-img');
        $wrap.height($(window).innerHeight());
        $hero.css('bottom', $main.outerHeight());
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Blog page
    'blog': {
      init: function() {


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
